'use strict';

goog.provide('Blockly.Blocks.tinusaur');

goog.require('Blockly.Blocks');

var help_url_global = "http://blocktinu.com";

// ---- Controls --------------------------------------------------------------

Blockly.Blocks['controls_repeat_forever'] = {
    helpUrl: help_url_global,
    init: function () {
		this.appendDummyInput()
			.appendField("repeat");
		this.appendStatementInput("DO")
			.setCheck(null);
			// .appendField("do");
		this.setInputsInline(true);
		this.setPreviousStatement(true, null);
		this.setNextStatement(true, null);
		this.setColour(0);
        this.setTooltip('Repeats forever');
    }
}

Blockly.Blocks['controls_while_do'] = {
    helpUrl: help_url_global,
    init: function () {
		this.appendValueInput("COND")
			.setCheck("Boolean")
			.appendField("while");
		this.appendStatementInput("DO")
			.setCheck(null)
			.appendField("do");
		this.setColour(120);
		// this.setInputsInline(true);
		this.setPreviousStatement(true, null);
		this.setNextStatement(true, null);
        this.setTooltip('While loop');
    }
}

Blockly.Blocks['controls_do_while'] = {
    helpUrl: help_url_global,
    init: function () {
		this.appendStatementInput("DO")
			.setCheck(null)
			.appendField("do");
		this.appendValueInput("COND")
			.setCheck("Boolean")
			.appendField("while");
		this.setColour(120);
		// this.setInputsInline(true);
		this.setPreviousStatement(true, null);
		this.setNextStatement(true, null);
        this.setTooltip('While loop');
    }
}

Blockly.Blocks['controls_for_do'] = {
    helpUrl: help_url_global,
    init: function () {
		this.appendValueInput("INIT")
			.setCheck(null)
			.appendField("for")
			.appendField(new Blockly.FieldVariable(null), "VARIABLE")
			.appendField("init");
		this.appendValueInput("COND")
			.setCheck(null)
			.appendField("cond");
		this.appendValueInput("CHANGE")
			.setCheck(null)
			.appendField("change");
		this.appendStatementInput("DO")
			.setCheck(null)
			.appendField("do");
		this.setInputsInline(true);
		this.setPreviousStatement(true, null);
		this.setNextStatement(true, null);
		this.setColour(120);
        this.setTooltip('While loop');
    }
}

Blockly.Blocks['controls_break'] = {
    helpUrl: help_url_global,
    init: function () {
        this.appendDummyInput()
            .appendField("break");
		this.setPreviousStatement(true, null);
		this.setNextStatement(true, null);
        this.setColour(120);
        this.setTooltip('Break out of the loop.');
    }
}

Blockly.Blocks['controls_continue'] = {
    helpUrl: help_url_global,
    init: function () {
        this.appendDummyInput()
            .appendField("continue");
		this.setPreviousStatement(true, null);
		this.setNextStatement(true, null);
        this.setColour(120);
        this.setTooltip('Continue (skip) an iteration of the loop.');
    }
}

Blockly.Blocks['controls_exit'] = {
    helpUrl: help_url_global,
    init: function () {
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/controls_exit.png", 18, 18))
            .appendField("exit");
		this.setPreviousStatement(true, null);
		this.setNextStatement(true, null);
        this.setColour('333333');
        this.setTooltip('Exit (immediately) the program.');
    }
}

// ---- Advanced Blocks ----------------------------------------------------------

Blockly.Blocks['advanced_nop'] = {
    helpUrl: help_url_global,
    init: function () {
		this.appendDummyInput()
			.appendField("NOP")
		this.setPreviousStatement(true, null);
		this.setNextStatement(true, null);
		this.setColour(45);
		this.setTooltip("This is a NOP operator");
    }
}

// ---- Other Blocks ----------------------------------------------------------

Blockly.Blocks['sourcecode_comment'] = {
    helpUrl: help_url_global,
    init: function () {
		this.appendDummyInput()
			.appendField("//")
			.appendField(new Blockly.FieldTextInput(""), "COMMENT");
		this.setColour(45);
		this.setTooltip("This is a comment");
    }
}

Blockly.Blocks['sourcecode_comment2'] = {
    helpUrl: help_url_global,
    init: function () {
		this.appendDummyInput()
			.appendField("//")
			.appendField(new Blockly.FieldTextInput(""), "COMMENT");
		this.setPreviousStatement(true, null);
		this.setNextStatement(true, null);
		this.setColour(45);
		this.setTooltip("This is a comment");
    }
}

Blockly.Blocks['sourcecode_testing'] = {
    helpUrl: help_url_global,
	// hat: "cap",	// Note: Should be used here OR below.
    init: function () {
		this.appendDummyInput()
			.appendField("TESTING >>")
			// .appendField(new Blockly.FieldMultilineInput(""), "TESTING");
			.appendField(new Blockly.FieldTextInput(""), "TESTING");
		// this.setPreviousStatement(true, null);
		this.setNextStatement(true, null);
		this.setColour(45);
		this.setTooltip("This is a testing block");
		// this.hat = "cap";	// Note: Should be used here OR above.
		// this.maxInstances = 2;
    }
}

// ---- Others ----------------------------------------------------------------

Blockly.Blocks['tinusaur_delay_ms'] = {
    helpUrl: help_url_global,
    init: function () {
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/lib_delay_ms.png", 36, 24))
            .appendField("Delay")
			.appendField(new Blockly.FieldNumber(1000, 0), "DELAY_TIME_MS")
            .appendField("ms");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(120);
        this.setTooltip('Delay specific time in milliseconds');
    }
};

Blockly.Blocks['tinusaur_delay_us'] = {
    helpUrl: help_url_global,
    init: function () {
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/lib_delay_us.png", 36, 24))
            .appendField("Delay")
			.appendField(new Blockly.FieldNumber(1000, 0), "DELAY_TIME_US")
            .appendField("us");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(120);
        this.setTooltip('Delay specific time in microseconds');
    }
};

// NOTE: Currently, this is not properly implemented, DO NOT include it in the toolbox.
Blockly.Blocks['tinusaur_map'] = {
    helpUrl: help_url_global,
    init: function () {
        this.setColour(230);
        this.appendValueInput("NUM", 'Number')
            .appendField("Map ")
            .setCheck('Number');
        this.appendValueInput("DMAX", 'Number')
            .appendField("value to [0-")
            .setCheck('Number');
        this.appendDummyInput()
            .appendField("]");
        this.setInputsInline(true);
        this.setOutput(true);
        this.setTooltip('Re-maps a number from [0-1024] to another.');
    }
};

// NOTE: Not properly implemented. DO NOT include it in the toolbox.
Blockly.Blocks['tinusaur_buildin_led'] = {
    helpUrl: help_url_global,
    init: function () {
        this.setColour(190);
        this.appendDummyInput()
            .appendField("Build-in LED Stat")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Turn on or off the default (build-in) LED at PB0');
    }
};

Blockly.Blocks['tinusaur_digital_write'] = {
    helpUrl: help_url_global,
    init: function () {
        this.setColour(230);
        this.appendDummyInput()
            .appendField("Output PORTB#")
            .appendField(new Blockly.FieldDropdown(board_profile.default.digital), "PIN")
            .appendField("Stat")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Write digital value to a specific I/O port');
    }
};

Blockly.Blocks['tinusaur_digital_read'] = {
    helpUrl: help_url_global,
    init: function () {
        this.setColour(230);
        this.appendDummyInput()
            .appendField("Input PORTB#")
            .appendField(new Blockly.FieldDropdown(board_profile.default.digital), "PIN");
        this.setOutput(true, 'Boolean');
        this.setTooltip('Read digital value from a specific I/O port');
    }
};

// NOTE: Not properly implemented. DO NOT include it in the toolbox.
Blockly.Blocks['tinusaur_analog_write'] = {
    helpUrl: help_url_global,
    init: function () {
        this.setColour(230);
        this.appendDummyInput()
            .appendField("AnalogWrite PIN#")
            .appendField(new Blockly.FieldDropdown(board_profile.default.digital), "PIN");
        this.appendValueInput("NUM", 'Number')
            .appendField("value")
            .setCheck('Number');
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Write analog value between 0 and 255 to a specific Port');
    }
};

// NOTE: Not properly implemented. DO NOT include it in the toolbox.
Blockly.Blocks['tinusaur_analog_read'] = {
    helpUrl: help_url_global,
    init: function () {
        this.setColour(230);
        this.appendDummyInput()
            .appendField("AnalogRead PIN#")
            .appendField(new Blockly.FieldDropdown(board_profile.default.analog), "PIN");
        this.setOutput(true, 'Number');
        this.setTooltip('Return value between 0 and 1023');
    }
};

// NOTE: Not properly implemented. DO NOT include it in the toolbox.
Blockly.Blocks['tinusaur_tone'] = {
    helpUrl: help_url_global,
    init: function () {
        this.setColour(230);
        this.appendDummyInput()
            .appendField("Tone PIN#")
            .appendField(new Blockly.FieldDropdown(board_profile.default.digital), "PIN");
        this.appendValueInput("NUM", "Number")
            .appendField("frequency")
            .setCheck("Number");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip("Generate audio tones on a pin");
    }
};

// NOTE: Not properly implemented. DO NOT include it in the toolbox.
Blockly.Blocks['tinusaur_notone'] = {
    helpUrl: help_url_global,
    init: function () {
        this.setColour(230);
        this.appendDummyInput()
            .appendField("No tone PIN#")
            .appendField(new Blockly.FieldDropdown(board_profile.default.digital), "PIN");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip("Stop generating a tone on a pin");
    }
};

// NOTE: DO NOT include it in the toolbox.
Blockly.Blocks['tinusaur_highlow'] = {
    helpUrl: help_url_global,
    init: function () {
        this.setColour(230);
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), 'BOOL')
        this.setOutput(true, 'Boolean');
        this.setTooltip('');
    }
};

// NOTE: Not properly implemented. DO NOT include it in the toolbox.
Blockly.Blocks['tinusaur_serial_print'] = {
    helpUrl: help_url_global,
    init: function () {
        this.setColour(230);
        this.appendValueInput("CONTENT", 'String')
            .appendField("Serial Print");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Prints data to the console/serial port as human-readable ASCII text.');
    }
};

// ---- Tinusaur Board --------------------------------------------------------

Blockly.Blocks['tinusaur_board_main_button'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "board:main";
        this.appendDummyInput()
            .appendField("Button")
			.appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/board_main_button.png", 48, 24));
        this.setOutput(true, 'Boolean');
        this.setColour(230);
        this.setTooltip('Tinusaur Main Board / Built-in Button \n'
			+ ' I/O Port: ' + board_profile.default.board_main.button + '');
    }
};

Blockly.Blocks['tinusaur_board_main_comout'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "board:main";
        this.setColour(190);
        this.appendDummyInput()
            .appendField("Board Output")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT")
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/board_main_comout.png", 48, 24));
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Tinusaur Main Board / Communication port - output '
			+ '\n I/O Port: ' + board_profile.default.board_main.comout + '');
    }
};

Blockly.Blocks['tinusaur_board_main_cominp'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "board:main";
        this.setColour(190);
        this.appendDummyInput()
            .appendField("Board Input")
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/board_main_cominp.png", 48, 24));
        this.setOutput(true, 'Boolean');
        this.setTooltip('Tinusaur Main Board - Communication port - input '
			+ '\n I/O Port: ' + board_profile.default.board_main.cominp + '');
    }
};

// ==== Tinusaur Shields ======================================================

// ---- Shield LEDx1 ----------------------------------------------------------

Blockly.Blocks['tinusaur_shield_ledx1_pb0'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:ledx1";
        this.setColour("DDDDDD");
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx1_led_blue.png", 24, 24))
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield LEDx1 / LED - Blue'
			+ '\n I/O Port: ' + board_profile.default.shield_ledx1.led + '');
    }
};

Blockly.Blocks['tinusaur_shield_ledx1'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:ledx1";
        this.setColour("DDDDDD");
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx1_led_blue.png", 24, 24))
            .appendField(new Blockly.FieldDropdown(board_profile.default.shield_ledx1.leds), "PIN")
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield LEDx1 / LED - Blue'
			+ '\n I/O Port: PB0, PB1, PB2, PB3, PB4');
    }
};

// ---- Shield LEDx2 ----------------------------------------------------------

// NOTE/LEGACY: Should be removed in the future.
Blockly.Blocks['tinusaur_shield_ledx2'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx2";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx2_leds.png", 48, 24))
            .appendField(new Blockly.FieldDropdown(board_profile.default.shield_ledx2.leds), "PIN")
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield LEDx2 / LEDs - Red, Green');
    }
};

Blockly.Blocks['tinusaur_shield_ledx2_led_red'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx2";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx2_led_red.png", 24, 24))
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield LEDx2 / LED - Red'
			+ '\n I/O Port: ' + board_profile.default.shield_ledx2.led_red + '');
    }
};

Blockly.Blocks['tinusaur_shield_ledx2_led_green'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx2";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx2_led_green.png", 24, 24))
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield LEDx2 / LED - Green'
			+ '\n I/O Port: ' + board_profile.default.shield_ledx2.led_green + '');
    }
};

Blockly.Blocks['tinusaur_shield_ledx2_comout'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx2";
        this.setColour(190);
        this.appendDummyInput()
            .appendField("Comm Output")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT")
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx2_comout.png", 48, 24));
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield LEDx2 / Communication port - output '
			+ '\n I/O Port: ' + board_profile.default.shield_ledx2.comout + '');
    }
};

Blockly.Blocks['tinusaur_shield_ledx2_cominp'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx2";
        this.setColour(190);
        this.appendDummyInput()
            .appendField("Comm Input")
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx2_cominp.png", 48, 24));
        this.setOutput(true, 'Boolean');
        this.setTooltip('Shield LEDx2 / Communication port - input '
			+ '\n I/O Port: ' + board_profile.default.shield_ledx2.cominp + '');
    }
};

Blockly.Blocks['tinusaur_shield_ledx2_extout'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx2";
        this.setColour(190);
        this.appendDummyInput()
            .appendField("Ext Output")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT")
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx2_extout.png", 48, 24));
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield LEDx2 / Extension port - output '
			+ '\n I/O Port: ' + board_profile.default.shield_ledx2.extout + '');
    }
};

Blockly.Blocks['tinusaur_shield_ledx2_extinp'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx2";
        this.setColour(190);
        this.appendDummyInput()
            .appendField("Ext Input")
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx2_extinp.png", 48, 24));
        this.setOutput(true, 'Boolean');
        this.setTooltip('Shield LEDx2 / Extension port - input '
			+ '\n I/O Port: ' + board_profile.default.shield_ledx2.extout + '');
    }
};

Blockly.Blocks['tinusaur_shield_ledx2_servo'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx2";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx2_servo.png", 48, 24))
            .appendField("Servo")
		this.appendValueInput("SERVO_POS")
			.setCheck("Number")
			.appendField("Position");
		this.appendValueInput("SERVO_REPEAT")
			.setCheck("Number")
			.appendField("Repeats");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield LEDx2 / Servo'
			+ '\n - Position: 0 .. 250'
			+ '\n - I/O Port: ' + board_profile.default.shield_ledx2.servo);
    }
};

// ---- Shield LEDx3 ----------------------------------------------------------

// NOTE/LEGACY: Should be removed in the future.
Blockly.Blocks['tinusaur_shield_ledx3'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx3";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx3_leds.png", 72, 24))
            .appendField(new Blockly.FieldDropdown(board_profile.default.shield_ledx3.leds), "PIN")
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield LEDx3 / LEDs - Red, Yellow, Green');
    }
};

Blockly.Blocks['tinusaur_shield_ledx3_led_red'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx3";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx3_led_red.png", 24, 24))
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield LEDx3 / LED - Red'
			+ '\n I/O Port: ' + board_profile.default.shield_ledx3.led_red + '');
    }
};

Blockly.Blocks['tinusaur_shield_ledx3_led_yellow'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx3";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx3_led_yellow.png", 24, 24))
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield LEDx3 / LED - Yellow'
			+ '\n I/O Port: ' + board_profile.default.shield_ledx3.led_yellow + '');
    }
};

Blockly.Blocks['tinusaur_shield_ledx3_led_green'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx3";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx3_led_green.png", 24, 24))
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield LEDx3 / LED - Green'
			+ '\n I/O Port: ' + board_profile.default.shield_ledx3.led_green + '');
    }
};

Blockly.Blocks['tinusaur_shield_ledx3_comout'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx3";
        this.setColour(190);
        this.appendDummyInput()
            .appendField("Comm")
            .appendField("Output")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT")
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx3_comout.png", 48, 24));
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield LEDx3 / Communication port - output');
        this.setTooltip('Shield LEDx3 / Communication port - output '
			+ '\n I/O Port: ' + board_profile.default.shield_ledx3.comout + '');
    }
};

Blockly.Blocks['tinusaur_shield_ledx3_cominp'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:ledx3";
        this.setColour(190);
        this.appendDummyInput()
            .appendField("Comm Input")
			.appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx3_cominp.png", 48, 24));
        this.setOutput(true, 'Boolean');
        this.setTooltip('Shield LEDx3 / Communication port - input');
        this.setTooltip('Shield LEDx3 / Communication port - input '
			+ '\n I/O Port: ' + board_profile.default.shield_ledx3.cominp + '');
    }
};

// ---- Shield EDUx3IO --------------------------------------------------------

Blockly.Blocks['tinusaur_shield_edux3io_led'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:edux3io";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_edux3io_led_white.png", 24, 24))
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield EDUx3IO / LED - White'
			+ '\n I/O Port: ' + board_profile.default.shield_edux3io.led + '');
    }
};

// TODO: Remove this block. It duplicates the LED block, which is confusing.
Blockly.Blocks['tinusaur_shield_edux3io_ledout'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:edux3io";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT")
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_edux3io_ledout.png", 48, 24));
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield EDUx3IO / LED Port - Output');
    }
};

Blockly.Blocks['tinusaur_shield_edux3io_buzzer'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:edux3io";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_edux3io_buzzer.png", 48, 24))
            .appendField("Buzzer");
		this.appendValueInput("PERIOD")
			.setCheck("Number")
			.appendField("Period");
		this.appendValueInput("REPEATS")
			.setCheck("Number")
			.appendField("Repeats");
		this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield EDUx3IO / Buzzer - Period and Repeats. 1 period for 1 repeat = 8 us'
			+ '\n I/O Port: ' + board_profile.default.shield_edux3io.buzzer + '');
    }
};
/*
Function _delay_loop_2 - 4 CPU cycles. At 1 MHz (1 us) that is 4 us.
For signal with frequency of 440 Hz:
  - signal period:  2.2727272727273 ms, or 2272.7272727273 us
  - pulse length:   1.1363636363636 ms, or 1136.3636363636 us
  - _delay_loop_2(284);	// period=568
*/

Blockly.Blocks['tinusaur_shield_edux3io_photores'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:edux3io";
        this.setColour(230);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_edux3io_photores.png", 36, 24))
            .appendField("Photoresistor");
        this.setOutput(true, 'Number');
        this.setTooltip('Shield EDUx3IO / Photoresistor - Value (between 0 and 1023)'
			+ '\n I/O Port: ' + board_profile.default.shield_edux3io.photores + '');
    }
};

Blockly.Blocks['tinusaur_shield_edux3io_servo'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:edux3io";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_edux3io_servo.png", 48, 24))
            .appendField("Servo")
		this.appendValueInput("SERVO_POS")
			.setCheck("Number")
			.appendField("Position");
		this.appendValueInput("SERVO_REPEAT")
			.setCheck("Number")
			.appendField("Repeats");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield EDUx3IO / Servo'
			+ '\n - Position: 0 .. 250'
			+ '\n - I/O Port: ' + board_profile.default.shield_edux3io.servo);
    }
};

Blockly.Blocks['tinusaur_shield_edux3io_comout'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:edux3io";
        this.setColour(190);
        this.appendDummyInput()
            .appendField("Comm Output")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT")
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_edux3io_comout.png", 48, 24));
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield EDUx3IO / Communication port - output '
			+ '\n I/O Port: ' + board_profile.default.shield_edux3io.comout + '');
    }
};

Blockly.Blocks['tinusaur_shield_edux3io_cominp'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:edux3io";
        this.setColour(190);
        this.appendDummyInput()
            .appendField("Comm Input")
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_edux3io_cominp.png", 48, 24));
        this.setOutput(true, 'Boolean');
        this.setTooltip('Shield EDUx3IO / Communication port - input '
			+ '\n I/O Port: ' + board_profile.default.shield_edux3io.cominp + '');
    }
};

Blockly.Blocks['tinusaur_shield_edux3io_extout'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:edux3io";
        this.setColour(190);
        this.appendDummyInput()
            .appendField("Ext Output")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT")
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_edux3io_extout.png", 48, 24));
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield EDUx3IO / Extension port - output '
			+ '\n I/O Port: ' + board_profile.default.shield_edux3io.extout + '');
    }
};

Blockly.Blocks['tinusaur_shield_edux3io_extinp'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:edux3io";
        this.setColour(190);
        this.appendDummyInput()
            .appendField("Ext Input")
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_edux3io_extinp.png", 48, 24));
        this.setOutput(true, 'Boolean');
        this.setTooltip('Shield EDUx3IO / Extension port - input '
			+ '\n I/O Port: ' + board_profile.default.shield_edux3io.extout + '');
    }
};

// TODO: Add Shield EDUx3IO / Communication port - output

// TODO: Add Shield EDUx3IO / Communication port - input

// TODO: Add Shield EDUx3IO / Extension port - output

// TODO: Add Shield EDUx3IO / Extension port - input

// ---- Shield MOVEx4IO --------------------------------------------------------

Blockly.Blocks['tinusaur_shield_movex4io_hallsens'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:movex4io";
        this.setColour(45);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_movex4io_hallsens.png", 48, 24))
            .appendField("Hall");
        this.setOutput(true, 'Number');
        this.setTooltip('Shield MOVEx4IO / Hall Effect Sensor'
			+ '\n - Value: 150 .. 850'
			+ '\n - I/O Port: ' + board_profile.default.shield_movex4io.hallsens);
    }
};

// ---- Shield MOVEx4IO --------------------------------------------------------

Blockly.Blocks['tinusaur_shield_motox4_motor1'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:motox4";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_motox4_motor1.png", 48, 24))
            .appendField("Motor 1 →")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield MOTOx4 / Motor 1 \n I/O Port: ' + board_profile.default.shield_motox4.motor1 + '');
    }
};

Blockly.Blocks['tinusaur_shield_motox4_motor2'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:motox4";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_motox4_motor2.png", 48, 24))
            .appendField("Motor 2 →")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield MOTOx4 / Motor 2 \n I/O Port: ' + board_profile.default.shield_motox4.motor2 + '');
    }
};

Blockly.Blocks['tinusaur_shield_motox4_servomots'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:motox4";
        this.setColour(190);
        this.appendDummyInput()
			.appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_motox4_servomot2.png", 48, 24));
		this.appendValueInput("SERVOMOT2_VAL")
			.setCheck("Number")
        this.appendDummyInput()
            .appendField("Motors")
		this.appendValueInput("SERVOMOT1_VAL")
			.setCheck("Number")
        this.appendDummyInput()
			.appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_motox4_servomot1.png", 48, 24));
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield MOTOx4 / Servo Motor 1 & 2 '
			+ '\n 1 ... 36 : moving forward'
			+ '\n 0 : not moving'
			+ '\n -1 ... -36 : moving backward'
			+ '\n I/O Ports: ' + board_profile.default.shield_motox4.motor1 + ' & ' + board_profile.default.shield_motox4.motor2);
    }
};

Blockly.Blocks['tinusaur_shield_motox4_irleds'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:motox4";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_motox4_irleds.png", 48, 24))
            .appendField("IR LEDs →")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield MOTOx4 / IR LEDs \n I/O Port: ' + board_profile.default.shield_motox4.irleds + '');
    }
};

Blockly.Blocks['tinusaur_shield_motox4_irsens'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:motox4";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_motox4_irsens.png", 48, 24))
            .appendField("←")
            .appendField(new Blockly.FieldDropdown(board_profile.default.shield_motox4.irsens), "IRSENS");
        this.setOutput(true, 'Number');
        this.setTooltip('Shield MOTOx4 / IR Sensor (between 0 and 1023)');
    }
}

// ---- Shield OLEDx1 --------------------------------------------------------

Blockly.Blocks['tinusaur_shield_oledx1_clear'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:oledx1";
		this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_oledx1_display.png", 32, 24))
			.appendField("OLED Clear");
        this.setInputsInline(true); // Make it inside
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(190);
        this.setTooltip('OLED Display Clear - Tinusaur Shield OLEDx1');
    }
};

Blockly.Blocks['tinusaur_shield_oledx1_fill'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:oledx1";
		this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_oledx1_display.png", 32, 24))
			.appendField("OLED Fill");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(190);
        this.setTooltip('OLED Display Fill - Tinusaur Shield OLEDx1');
    }
};

Blockly.Blocks['tinusaur_shield_oledx1_text'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:oledx1";
		this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_oledx1_display.png", 32, 24))
			.appendField("OLED");
        this.appendDummyInput()
            .appendField("X")
			.appendField(new Blockly.FieldNumber(0, 0, 15), "POSX");
        this.appendDummyInput()
            .appendField("Y")
			.appendField(new Blockly.FieldNumber(0, 0, 7), "POSY");
		this.appendDummyInput()
			.appendField("Text")
			.appendField(new Blockly.FieldTextInput(""), "TEXT");
        this.setInputsInline(true); // Make it inside
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(190);
        this.setTooltip('OLED Display Text - Tinusaur Shield OLEDx1\n' +
			'X = 0..15\n' +
			'Y = 0..7');
    }
};

Blockly.Blocks['tinusaur_shield_oledx1_nump'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:oledx1";
		this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_oledx1_display.png", 32, 24))
			.appendField("OLED");
        this.appendDummyInput()
            .appendField("X")
			.appendField(new Blockly.FieldNumber(0, 0, 15), "POSX");
        this.appendDummyInput()
            .appendField("Y")
			.appendField(new Blockly.FieldNumber(0, 0, 7), "POSY");
		this.appendValueInput("NUM")
			.setCheck("Number")
			.appendField("Value");
        this.setInputsInline(true); // Make it inside
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(190);
        this.setTooltip('OLED Display Number - Tinusaur Shield OLEDx1\n' +
			'X = 0..15\n' +
			'Y = 0..7');
    }
};

/*
*/

// ---- Shield LEDx12 --------------------------------------------------------

Blockly.Blocks['tinusaur_shield_ledx12'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:ledx12";
		this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx12.png", 36, 24))
			.appendField("LEDx12");
		this.appendValueInput("CHRLPLXNG_LED")
			.setCheck("Number")
			.appendField("LED");
        this.setInputsInline(true); // Make it inside
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(190);
        this.setTooltip('Turn a LED on - Tinusaur Shield LEDx12 Charlieplexing');
    }
};

Blockly.Blocks['tinusaur_shield_ledx12_off'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:ledx12";
		this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_ledx12off.png", 36, 24))
			.appendField("LEDx12");
		this.appendDummyInput()
			.appendField("Off");
        this.setInputsInline(true); // Make it inside
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(190);
        this.setTooltip('Turn all LEDs off - Tinusaur Shield LEDx12 Charlieplexing');
    }
};

/*
Blockly.Blocks['tinusaur_shield_ledx12_OLD'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:ledx12";
		this.appendDummyInput()
			.appendField("LEDx12 LEDs:");
		this.appendDummyInput()
			.appendField("00")
			.appendField(new Blockly.FieldCheckbox("FALSE"), "LED00")
			.appendField("01")
			.appendField(new Blockly.FieldCheckbox("FALSE"), "LED01")
			.appendField("02")
			.appendField(new Blockly.FieldCheckbox("FALSE"), "LED02")
			.appendField("03")
			.appendField(new Blockly.FieldCheckbox("FALSE"), "LED03");
		this.appendDummyInput()
			.appendField("04")
			.appendField(new Blockly.FieldCheckbox("FALSE"), "LED04")
			.appendField("05")
			.appendField(new Blockly.FieldCheckbox("FALSE"), "LED05")
			.appendField("06")
			.appendField(new Blockly.FieldCheckbox("FALSE"), "LED06")
			.appendField("07")
			.appendField(new Blockly.FieldCheckbox("FALSE"), "LED07");
		this.appendDummyInput()
			.appendField("08")
			.appendField(new Blockly.FieldCheckbox("FALSE"), "LED08")
			.appendField("09")
			.appendField(new Blockly.FieldCheckbox("FALSE"), "LED09")
			.appendField("10")
			.appendField(new Blockly.FieldCheckbox("FALSE"), "LED10")
			.appendField("11")
			.appendField(new Blockly.FieldCheckbox("FALSE"), "LED11");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(190);
        this.setTooltip('Shield LEDx12');
    }
};
*/
// ---- LEGACY: Shield EDUx4IO --------------------------------------------------------

Blockly.Blocks['tinusaur_shield_edux4io_led'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:edux4io";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_edux4io_led_red.png", 24, 24))
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield EDUx4IO - Red LED'
			+ '\n I/O Port: ' + board_profile.default.shield_edux4io.led + '');
    }
};

Blockly.Blocks['tinusaur_shield_edux4io_buzzer'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:edux4io";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_edux4io_buzzer.png", 48, 24))
            .appendField("Buzzer");
		this.appendValueInput("PERIOD")
			.setCheck("Number")
			.appendField("Period");
		this.appendValueInput("REPEATS")
			.setCheck("Number")
			.appendField("Repeats");
        this.setInputsInline(true); // Make it inside
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Shield EDUx4IO / Buzzer - Period and Repeats.');
    }
};

Blockly.Blocks['tinusaur_shield_edux4io_button'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:edux4io";
        this.setColour(230);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_edux4io_button.png", 48, 24))
            .appendField("Button");
        this.setOutput(true, 'Boolean');
        this.setTooltip('Shield EDUx4IO - Button');
    }
};

Blockly.Blocks['tinusaur_shield_edux4io_photores'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:edux4io";
        this.setColour(230);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/shield_edux4io_photores.png", 36, 24))
            .appendField("Photores");
        this.setOutput(true, 'Number');
        this.setTooltip('Shield EDUx4IO - Photoresistor (between 0 and 1023)');
    }
};

// ---- Board Kids.Micro --------------------------------------------------------

Blockly.Blocks['tinusaur_board_kidsmicro_led_red'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "board:kidsmicro";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/board_kidsmicro_led_red.png", 24, 24))
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Board KidsMicro / LED - Red '
			+ '\n I/O Port: ' + board_profile.default.board_kidsmicro.led_red + '');
    }
};

Blockly.Blocks['tinusaur_board_kidsmicro_led_green'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "board:kidsmicro";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/board_kidsmicro_led_green.png", 24, 24))
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Board KidsMicro / LED - Green '
			+ '\n I/O Port: ' + board_profile.default.board_kidsmicro.led_green + '');
    }
};

Blockly.Blocks['tinusaur_board_kidsmicro_button'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "board:kidsmicro";
        this.appendDummyInput()
            .appendField("Button")
			.appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/board_kidsmicro_button.png", 48, 24));
        this.setOutput(true, 'Boolean');
        this.setColour(230);
        this.setTooltip('Tinusaur KidsMicro / Button \n I/O Port: ' + board_profile.default.board_kidsmicro.button + '');
    }
};

Blockly.Blocks['tinusaur_board_kidsmicro_buzzer'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "board:kidsmicro";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/board_kidsmicro_buzzer.png", 48, 24))
            .appendField("Buzzer");
		this.appendValueInput("PERIOD")
			.setCheck("Number")
			.appendField("Period");
		this.appendValueInput("REPEATS")
			.setCheck("Number")
			.appendField("Repeats");
		this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Board KidsMicro - Buzzer. 1 period for 1 repeat = 8 us \n I/O Port: ' + board_profile.default.board_kidsmicro.buzzer + '');
    }
};

Blockly.Blocks['tinusaur_board_kidsmicro_photores'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "board:kidsmicro";
        this.setColour(230);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/board_kidsmicro_photores.png", 36, 24))
            .appendField("Photoresistor");
        this.setOutput(true, 'Number');
        this.setTooltip('Board KidsMicro / Photoresistor - Value (between 0 and 1023) \n I/O Port: ' + board_profile.default.board_kidsmicro.photores + '');
    }
};

// ---- Board Kids.Mini --------------------------------------------------------

Blockly.Blocks['tinusaur_board_kidsmini_led_white'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "board:kidsmini";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/board_kidsmini_led_white.png", 24, 24))
            .appendField("←")
            .appendField(new Blockly.FieldDropdown([["HIGH", "HIGH"], ["LOW", "LOW"]]), "STAT");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Board KidsMini / LED - White '
			+ '\n I/O Port: ' + board_profile.default.board_kidsmini.led_white + '');
    }
};

Blockly.Blocks['tinusaur_board_kidsmini_button'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "board:kidsmini";
        this.appendDummyInput()
            .appendField("Button")
			.appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/board_kidsmini_button.png", 48, 24));
        this.setOutput(true, 'Boolean');
        this.setColour(230);
        this.setTooltip('Tinusaur KidsMini / Button \n I/O Port: ' + board_profile.default.board_kidsmini.button + '');
    }
};

Blockly.Blocks['tinusaur_board_kidsmini_buzzer'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "board:kidsmini";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/board_kidsmini_buzzer.png", 48, 24))
            .appendField("Buzzer");
		this.appendValueInput("PERIOD")
			.setCheck("Number")
			.appendField("Period");
		this.appendValueInput("REPEATS")
			.setCheck("Number")
			.appendField("Repeats");
		this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Board KidsMini - Buzzer. 1 period for 1 repeat = 8 us \n I/O Port: ' + board_profile.default.board_kidsmini.buzzer + '');
    }
};

Blockly.Blocks['tinusaur_board_kidsmini_photores'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "board:kidsmini";
        this.setColour(230);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/board_kidsmini_photores.png", 36, 24))
            .appendField("Photoresistor");
        this.setOutput(true, 'Number');
        this.setTooltip('Board KidsMini / Photoresistor - Value (between 0 and 1023) \n I/O Port: ' + board_profile.default.board_kidsmini.photores + '');
    }
};

Blockly.Blocks['tinusaur_board_kidsmini_servo'] = {
    helpUrl: help_url_global,
    init: function () {
        this.assoc = "shield:kidsmini";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/board_kidsmini_servo.png", 48, 24))
            .appendField("Servo")
		this.appendValueInput("SERVO_POS")
			.setCheck("Number")
			.appendField("Position");
		this.appendValueInput("SERVO_REPEAT")
			.setCheck("Number")
			.appendField("Repeats");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setTooltip('Board KidsMini / Servo'
			+ '\n - Position: 0 .. 250'
			+ '\n - I/O Port: ' + board_profile.default.board_kidsmini.servo);
    }
};

// ---- Library: TinyAVRLib ---------------------------------------------------

Blockly.Blocks['library_tinyavrlib_adcx_read'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:motox4";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown(board_profile.default.tinyavrlib_adcxlib.adcx), "ADCX")
            .appendField("←")
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/library_tinyavrlib_adcx_read.png", 48, 24));
        this.setOutput(true, 'Number');
        this.setTooltip('ADC(0..4) Read / TinyAVRLib library, ADCxLib Module (value between 0 and 1023)\n'
			+ ' - ADC0 (PB5)\n - ADC1 (PB2)\n - ADC2 (PB4)\n - ADC3 (PB3)\n'
			+ ' - ADC4 (internal temperature sensor)');
    }
};

Blockly.Blocks['library_tinyavrlib_adcx_temp'] = {
    helpUrl: help_url_global,
    init: function () {
        // this.assoc = "shield:motox4";
        this.setColour(190);
        this.appendDummyInput()
            .appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/library_tinyavrlib_adcx_temp.png", 48, 24));
        this.setOutput(true, 'Number');
        this.setTooltip('ADC4 Read / TinyAVRLib library, ADCxLib Module (value between 0 and 1023)\n'
			+ ' - ADC4 => internal temperature sensor');
    }
};

// Type Checks: https://developers.google.com/blockly/guides/create-custom-blocks/type-checks
// IDEAS:
// -- to allow to insert only functions in the bracket.
// -- to allow to insert only one function in the bracket.
// -- to allow to insert only specific custom type of function (a handler).
Blockly.Blocks['library_tinyavrlib_scheduler'] = {
    helpUrl: help_url_global,
    init: function () {
		this.appendDummyInput()
			.appendField(new Blockly.FieldImage("assets/images/tinusaur/blocks/scheduler_usertask.png", 32, 24))
			.appendField("Scheduler")
			.appendField(new Blockly.FieldNumber(100, 0, 65535), "COUNT")
		this.appendStatementInput("DO")
			.setCheck(null);
		this.setColour(0);
		this.setTooltip('Scheduler - each 10ms, or after counter goes down to 0.' +
			' - Counter: from 0 to 65535 (from 10ms to 655.35sec.)'
			);
    }
}
