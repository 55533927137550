/**
 * Visual Blocks Language
 *
 * Copyright 2012 Google Inc.
 * http://blockly.googlecode.com/
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Variable blocks for Tinusaur.
 * @author gasolin@gmail.com (Fred Lin)
 */

'use strict';

goog.provide('Blockly.Tinusaur.variables');

goog.require('Blockly.Tinusaur');

Blockly.Tinusaur['variables_get'] = function (block) {
	// Variable getter.
	var code = Blockly.Tinusaur.variableDB_.getName(
			block.getFieldValue('VAR'), Blockly.Variables.NAME_TYPE);
	return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

Blockly.Tinusaur['variables_declare'] = function (block) {
	// Variable setter.
	var dropdown_type = block.getFieldValue('TYPE');
	//TODO: settype to variable
	var argument0 = Blockly.Tinusaur.valueToCode(block, 'VALUE',
			Blockly.Tinusaur.ORDER_ASSIGNMENT) || '0';
	var varName = Blockly.Tinusaur.variableDB_.getName(
			block.getFieldValue('VAR'), Blockly.Variables.NAME_TYPE);
	Blockly.Tinusaur.variables_['setup_var_' + varName] = varName + ' = ' + argument0 + ';\n';
	return '';
};

Blockly.Tinusaur['variables_set'] = function (block) {
	// Variable setter.
	var argument0 = Blockly.Tinusaur.valueToCode(block, 'VALUE',
			Blockly.Tinusaur.ORDER_ASSIGNMENT) || '0';
	var varName = Blockly.Tinusaur.variableDB_.getName(
			block.getFieldValue('VAR'), Blockly.Variables.NAME_TYPE);
	return varName + ' = ' + argument0 + ';\n';
};
