/**
 * Tinusaur code generator.
 * @type !Blockly.Generator
 */

'use strict';

goog.provide('Blockly.Tinusaur');

goog.require('Blockly.Generator');

Blockly.Tinusaur = new Blockly.Generator('Tinusaur');

/**
 * List of illegal variable names.
 * This is not intended to be a security feature.  Blockly is 100% client-side,
 * so bypassing this list is trivial.  This is intended to prevent users from
 * accidentally clobbering a built-in object or function.
 * @private
 */
Blockly.Tinusaur.addReservedWords(
		// Derived from http://arduino.cc/en/Reference/HomePage
		'setup,loop,if,else,for,switch,case,while,do,break,continue,return,goto,define,include,HIGH,LOW,INPUT,OUTPUT,INPUT_PULLUP,true,false,interger, constants,floating,point,void,bookean,char,unsigned,byte,int,word,long,float,double,string,String,array,static, volatile,const,sizeof,pinMode,digitalWrite,digitalRead,analogReference,analogRead,analogWrite,tone,noTone,shiftOut,shitIn,pulseIn,millis,micros,delay,delayMicroseconds,min,max,abs,constrain,map,pow,sqrt,sin,cos,tan,randomSeed,random,lowByte,highByte,bitRead,bitWrite,bitSet,bitClear,bit,attachInterrupt,detachInterrupt,interrupts,noInterrupts'
		);

/**
 * Order of operation ENUMs.
 *
 */
Blockly.Tinusaur.ORDER_ATOMIC = 0;         // 0 "" ...
Blockly.Tinusaur.ORDER_UNARY_POSTFIX = 1;  // expr++ expr-- () [] .
Blockly.Tinusaur.ORDER_UNARY_PREFIX = 2;   // -expr !expr ~expr ++expr --expr
Blockly.Tinusaur.ORDER_MULTIPLICATIVE = 3; // * / % ~/
Blockly.Tinusaur.ORDER_ADDITIVE = 4;       // + -
Blockly.Tinusaur.ORDER_SHIFT = 5;          // << >>
Blockly.Tinusaur.ORDER_RELATIONAL = 6;     // is is! >= > <= <
Blockly.Tinusaur.ORDER_EQUALITY = 7;       // == != === !==
Blockly.Tinusaur.ORDER_BITWISE_AND = 8;    // &
Blockly.Tinusaur.ORDER_BITWISE_XOR = 9;    // ^
Blockly.Tinusaur.ORDER_BITWISE_OR = 10;    // |
Blockly.Tinusaur.ORDER_LOGICAL_AND = 11;   // &&
Blockly.Tinusaur.ORDER_LOGICAL_OR = 12;    // ||
Blockly.Tinusaur.ORDER_CONDITIONAL = 13;   // expr ? expr : expr
Blockly.Tinusaur.ORDER_ASSIGNMENT = 14;    // = *= /= ~/= %= += -= <<= >>= &= ^= |=
Blockly.Tinusaur.ORDER_NONE = 99;          // (...)

/**
 * Initialise the database of variable names.
 * @param {!Blockly.Workspace} workspace Workspace to generate code from.
 */
Blockly.Tinusaur.init = function (workspace) {
	// Create a dictionaries for ... 
	Blockly.Tinusaur.includes_ = Object.create(null);	// includes, such as "#include ...", to be printed before the code.
	Blockly.Tinusaur.definitions_ = Object.create(null);	// definitions, such as "#define ...", to be printed before the code.
	Blockly.Tinusaur.variables_ = Object.create(null);	// declarations, such as variables, to be printed before the code.
	Blockly.Tinusaur.declarations_ = Object.create(null);	// declarations, such as functions, to be printed before the code.
	Blockly.Tinusaur.setups_ = Object.create(null);	// setups to be printed before the code.
	// Create a dictionary of libraries to be printed before the code.
	Blockly.Tinusaur.libraries_ = Object.create(null);
	
	// INITIALIZATION
	// AVR-LibC includes, by default
	Blockly.Tinusaur.includes_['avrlibc-avr-io'] = '#include <avr/io.h>';
	Blockly.Tinusaur.includes_['avrlibc-stdbool'] = '#include <stdbool.h>'; // TO-DO: include only if necessary (for true/false statements)
	// Blockly.Tinusaur.includes_['avrlibc-stdint'] = '#include <stdint.h>';
	// Blockly.Tinusaur.includes_['avrlibc-stdlib'] = '#include <stdlib.h>';
	// The blocktinu-clibt85 library, included by default - no longer needed by any module.
	// Blockly.Tinusaur.includes_['blocktinu-clibt85'] = '#include "blocktinu-clibt85/blocktinu-clibt85.h"';
	// Blockly.Tinusaur.libraries_['blocktinu-clibt85'] = 'blocktinu-clibt85';

	if (!Blockly.Tinusaur.variableDB_) {
		Blockly.Tinusaur.variableDB_ =
			new Blockly.Names(Blockly.Tinusaur.RESERVED_WORDS_);
	} else {
		Blockly.Tinusaur.variableDB_.reset();
	}
	Blockly.Tinusaur.variableDB_.setVariableMap(workspace.getVariableMap());
	// REF: https://developers.google.com/blockly/reference/js/Blockly.Variables
	// Blockly.Variables.allUsedVarModels(workspace); - That will add all (used only) variables.
	// workspace.getAllVariables() - That will add all (including unused) variables.
	var defvars = [];
	// Add developer variables (not created or named by the user).
	var devVarList = Blockly.Variables.allDeveloperVariables(workspace);
	for (var i = 0; i < devVarList.length; i++) {
		defvars.push(Blockly.Tinusaur.variableDB_.getName(devVarList[i], Blockly.Names.DEVELOPER_VARIABLE_TYPE));
	}
	// Add user variables, but only ones that are being used.
	var variables = Blockly.Variables.allUsedVarModels(workspace);
	for (var i = 0; i < variables.length; i++) {
		defvars.push(Blockly.Tinusaur.variableDB_.getName(variables[i].getId(), Blockly.Variables.NAME_TYPE));
	}
	// Declare all of the variables.
	if (defvars.length) {
		Blockly.Tinusaur.variables_['defvars'] = 'int ' + defvars.join(', ') + ';';
	}
};

/**
 * Prepare the generated code with the various definitions.
 * @param {string} code Generated code.
 * @return {string} Completed code.
 */
Blockly.Tinusaur.finish = function (code) {

	// Convert the dictionaries into lists.
	var includes = [];
	for (var name in Blockly.Tinusaur.includes_) includes.push(Blockly.Tinusaur.includes_[name]);
	var definitions = [];
	for (var name in Blockly.Tinusaur.definitions_) definitions.push(Blockly.Tinusaur.definitions_[name]);
	var variables = [];
	for (var name in Blockly.Tinusaur.variables_) variables.push(Blockly.Tinusaur.variables_[name]);
	var declarations = [];
	for (var name in Blockly.Tinusaur.declarations_) declarations.push(Blockly.Tinusaur.declarations_[name]);
	var setups = [];
	for (var name in Blockly.Tinusaur.setups_) setups.push(Blockly.Tinusaur.setups_[name]);

	var libraries = [];
	for (var name in Blockly.Tinusaur.libraries_) libraries.push(Blockly.Tinusaur.libraries_[name]);
	Blockly.Tinusaur.libs = libraries.join(',');

	// Indent every line.
	code = '\t' + code.replace(/\n/g, '\n\t');
	code = code.replace(/\n\s+$/, '\n'); // Strip whitespace
	code =
		'int main(void) {\t// The main function\n' +
		(setups.length !== 0 ? 
			'\t// Initialization\n' +
			'\t' + setups.join('\n\t') + '\n\n'
			: '') +
		code + '\n' +
		'\treturn 0;\t// End of the program.\n' +
		'}\n';

	var allDefs = 
		includes.join('\n') + '\n\n' + 
		(libraries.length !== 0 ? '// Required libraries: ' + libraries.join(' ') + '\n\n' : '') + 
		definitions.join('\n') + '\n\n' + 
		variables.join('\n') + '\n\n' + 
		declarations.join('\n') + '\n\n' + 
		'';
	return allDefs.replace(/\n\n+/g, '\n\n').replace(/\n*$/, '\n\n') + code;
};

/**
 * Naked values are top-level blocks with outputs that aren't plugged into
 * anything.  A trailing semicolon is needed to make this legal.
 * @param {string} line Line of generated code.
 * @return {string} Legal line of code.
 */
Blockly.Tinusaur.scrubNakedValue = function (line) {
	return line + ';\n';
};

/**
 * Encode a string as a properly escaped Arduino string, complete with quotes.
 * @param {string} string Text to encode.
 * @return {string} Arduino string.
 * @private
 */
Blockly.Tinusaur.quote_ = function (string) {
	// TODO: This is a quick hack.  Replace with goog.string.quote
	string = string.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\\n')
		.replace(/\$/g, '\\$')
		.replace(/'/g, '\\\'');
	return '\"' + string + '\"';
};

/**
 * Common tasks for generating Arduino from blocks.
 * Handles comments for the specified block and any connected value blocks.
 * Calls any statements following this block.
 * @param {!Blockly.Block} block The current block.
 * @param {string} code The Arduino code created for this block.
 * @return {string} Arduino code with comments and subsequent blocks added.
 * @private
 */
Blockly.Tinusaur.scrub_ = function (block, code) {
	if (code === null) {
		// Block has handled code generation itself.
		return '';
	}
	var commentCode = '';
	// Only collect comments for blocks that aren't inline.
	if (!block.outputConnection || !block.outputConnection.targetConnection) {
		// Collect comment for this block.
		var comment = block.getCommentText();
		if (comment) {
			commentCode += Blockly.Tinusaur.prefixLines(comment, '// ') + '\n';
		}
		// Collect comments for all value arguments.
		// Don't collect comments for nested statements.
		for (var x = 0; x < block.inputList.length; x++) {
			if (block.inputList[x].type == Blockly.INPUT_VALUE) {
				var childBlock = block.inputList[x].connection.targetBlock();
				if (childBlock) {
					var comment = Blockly.Tinusaur.allNestedComments(childBlock);
					if (comment) {
						commentCode += Blockly.Tinusaur.prefixLines(comment, '// ');
					}
				}
			}
		}
	}
	var nextBlock = block.nextConnection && block.nextConnection.targetBlock();
	var nextCode = Blockly.Tinusaur.blockToCode(nextBlock);
	return commentCode + code + nextCode;
};
