/**
 * Visual Blocks Language
 *
 * Copyright 2012 Google Inc.
 * http://blockly.googlecode.com/
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Generating Tinusaur for control blocks.
 * @author gasolin@gmail.com  (Fred Lin)
 */

'use strict';

goog.provide('Blockly.Tinusaur.loops');

goog.require('Blockly.Tinusaur');

Blockly.Tinusaur.controls_repeat_forever = function() {
	var branch = Blockly.Tinusaur.statementToCode(this, 'DO');
	var code = 
		'for (;;) {\t\t\t// Infinite loop\n' + 
			'' + branch + 
			'}\t\t\t\t\t// End of the infinite loop\n';
	return code;
};

Blockly.Tinusaur.controls_while_do = function() {
	var cond = Blockly.Tinusaur.valueToCode(this, 'COND', Blockly.Tinusaur.ORDER_NONE) || 'false';
	var branch = Blockly.Tinusaur.statementToCode(this, 'DO');
	var code = 
		'while (' + cond + ') {\n' + 
			'' + branch + 
			'}\n';
	return code;
};

Blockly.Tinusaur.controls_do_while = function() {
	var branch = Blockly.Tinusaur.statementToCode(this, 'DO');
	var cond = Blockly.Tinusaur.valueToCode(this, 'COND', Blockly.Tinusaur.ORDER_NONE) || 'false';
	var code = 
		'do {\n' + 
			'' + branch + 
			'} while (' + cond + ');\n';
	return code;
};

Blockly.Tinusaur.controls_for_do = function() {
	var variable = Blockly.Tinusaur.variableDB_.getName(this.getFieldValue('VARIABLE'), Blockly.Variables.NAME_TYPE);
	var init = Blockly.Tinusaur.valueToCode(this, 'INIT', Blockly.Tinusaur.ORDER_NONE);
	var cond = Blockly.Tinusaur.valueToCode(this, 'COND', Blockly.Tinusaur.ORDER_NONE) || 'false';
	var change = Blockly.Tinusaur.valueToCode(this, 'CHANGE', Blockly.Tinusaur.ORDER_NONE);
	var oper;
	if (!isNaN(change)) {
		switch (Number(change)) {
			case -1:	oper = variable + '--'; break;
			case 0: 	oper = '/*no-change*/'; break;
			case 1:		oper = variable + '++'; break;
			default:
				if (change >= 0 ) oper = variable + ' += ' + change;
				else oper = variable + ' -= ' + (- change);
			break;
		}
	} else {
		oper = variable + ' += ' + change;
	}
	var branch = Blockly.Tinusaur.statementToCode(this, 'DO');
	var code = 
		// '// variable: ' + variable + '\n' +
		// '// init: ' + init + '\n' +
		// '// cond: ' + cond + '\n' +
		// '// change: ' + change + '\n' +
		'for (' + variable + ' = ' + init + '; ' + cond + '; ' + oper + ') {\n' + 
			branch + 
			'};\n';
	return code;
};

Blockly.Tinusaur.controls_break = function() {
	var code = 'break;\t\t\t// Immediately exit the loop\n';
	return code;
};

Blockly.Tinusaur.controls_continue = function() {
	var code = 'continue;\t\t\t// Skip an iteration of the loop\n';
	return code;
};

Blockly.Tinusaur.controls_exit = function() {
	var code = 'exit(0);\t\t\t// Immediately exit the program\n';
	return code;
};

// ---- Other Blocks ----------------------------------------------------------

Blockly.Tinusaur.controls_for = function() {
  // For loop.
  var variable0 = Blockly.Tinusaur.variableDB_.getName(
      this.getFieldValue('VAR'), Blockly.Variables.NAME_TYPE);
  var argument0 = Blockly.Tinusaur.valueToCode(this, 'FROM',
      Blockly.Tinusaur.ORDER_ASSIGNMENT) || '0';
  var argument1 = Blockly.Tinusaur.valueToCode(this, 'TO',
      Blockly.Tinusaur.ORDER_ASSIGNMENT) || '0';
  var branch = Blockly.Tinusaur.statementToCode(this, 'DO');
  if (Blockly.Tinusaur.INFINITE_LOOP_TRAP) {
    branch = Blockly.Tinusaur.INFINITE_LOOP_TRAP.replace(/%1/g,
        '\'' + this.id + '\'') + branch;
  }
  var code;
  if (argument0.match(/^-?\d+(\.\d+)?$/) &&
      argument1.match(/^-?\d+(\.\d+)?$/)) {
    // Both arguments are simple numbers.
    var up = parseFloat(argument0) <= parseFloat(argument1);
    code = 'for (' + variable0 + ' = ' + argument0 + '; ' +
        variable0 + (up ? ' <= ' : ' >= ') + argument1 + '; ' +
        variable0 + (up ? '++' : '--') + ') {\n' +
        branch + '}\n';
  } else {
    code = '';
    // Cache non-trivial values to variables to prevent repeated look-ups.
    var startVar = argument0;
    if (!argument0.match(/^\w+$/) && !argument0.match(/^-?\d+(\.\d+)?$/)) {
      var startVar = Blockly.Tinusaur.variableDB_.getDistinctName(
          variable0 + '_start', Blockly.Variables.NAME_TYPE);
      code += 'int ' + startVar + ' = ' + argument0 + ';\n';
    }
    var endVar = argument1;
    if (!argument1.match(/^\w+$/) && !argument1.match(/^-?\d+(\.\d+)?$/)) {
      var endVar = Blockly.Tinusaur.variableDB_.getDistinctName(
          variable0 + '_end', Blockly.Variables.NAME_TYPE);
      code += 'int ' + endVar + ' = ' + argument1 + ';\n';
    }

    code += `
      for ( ${variable0} = ${startVar};
          (${startVar} <= ${endVar}) ? ${variable0} <= ${endVar} : ${variable0} >= ${endVar};
          ${variable0} += (${startVar} <= ${endVar}) ? 1 : -1) {
           ${branch}
          }
    `;
  }
  return code;
};

Blockly.Tinusaur.controls_whileUntil = function() {
  // Do while/until loop.
  var until = this.getFieldValue('MODE') == 'UNTIL';
  var argument0 = Blockly.Tinusaur.valueToCode(this, 'BOOL',
      until ? Blockly.Tinusaur.ORDER_LOGICAL_NOT :
      Blockly.Tinusaur.ORDER_NONE) || 'false';
  var branch = Blockly.Tinusaur.statementToCode(this, 'DO');
  if (Blockly.Tinusaur.INFINITE_LOOP_TRAP) {
    branch = Blockly.Tinusaur.INFINITE_LOOP_TRAP.replace(/%1/g,
        '\'' + this.id + '\'') + branch;
  }
  if (until) {
    argument0 = '!' + argument0;
  }
  return 'while (' + argument0 + ') {\n' + branch + '}\n';
};
