/**
 * Visual Blocks Language
 *
 * Copyright 2012 Google Inc.
 * http://blockly.googlecode.com/
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Generating Tinusaur for math blocks.
 * @author gasolin@gmail.com  (Fred Lin)
 */

'use strict';

goog.provide('Blockly.Tinusaur.math');

goog.require('Blockly.Tinusaur');

Blockly.Tinusaur.math_number = function () {
    // Numeric value.
    var code = window.parseFloat(this.getFieldValue('NUM'));
    // -4.abs() returns -4 in Dart due to strange order of operation choices.
    // -4 is actually an operator and a number.  Reflect this in the order.
    var order = code < 0 ?
            Blockly.Tinusaur.ORDER_UNARY_PREFIX : Blockly.Tinusaur.ORDER_ATOMIC;
    return [code, order];
};

Blockly.Tinusaur.math_arithmetic = function () {
    // Basic arithmetic operators, and power.
    var mode = this.getFieldValue('OP');
    var tuple = Blockly.Tinusaur.math_arithmetic.OPERATORS[mode];
    var operator = tuple[0];
    var order = tuple[1];
    var argument0 = Blockly.Tinusaur.valueToCode(this, 'A', order) || '0';
    var argument1 = Blockly.Tinusaur.valueToCode(this, 'B', order) || '0';
    var code;
    if (!operator) {
        code = 'Math.pow(' + argument0 + ', ' + argument1 + ')';
        return [code, Blockly.Tinusaur.ORDER_UNARY_POSTFIX];
    }
    code = argument0 + operator + argument1;
    return [code, order];
};

Blockly.Tinusaur.math_arithmetic.OPERATORS = {
    ADD: [' + ', Blockly.Tinusaur.ORDER_ADDITIVE],
    MINUS: [' - ', Blockly.Tinusaur.ORDER_ADDITIVE],
    MULTIPLY: [' * ', Blockly.Tinusaur.ORDER_MULTIPLICATIVE],
    DIVIDE: [' / ', Blockly.Tinusaur.ORDER_MULTIPLICATIVE],
    POWER: [null, Blockly.Tinusaur.ORDER_NONE]  // Handle power separately.
};

Blockly.Tinusaur['math_change'] = function (block) {
    // Add to a variable in place.
    var argument0 = Blockly.Tinusaur.valueToCode(block, 'DELTA', Blockly.Tinusaur.ORDER_ADDITIVE) || '0';
    var varName = Blockly.Tinusaur.variableDB_.getName(block.getFieldValue('VAR'), Blockly.Variables.NAME_TYPE);
    var code;
	if (!isNaN(argument0)) {
		switch (Number(argument0)) {
			case -1:
				code = varName + '--;	// Decrement\n';
			break;
			case 0:
				code = '\t\t// no change for variable "' + varName + '"\n';
			break;
			case 1:
				code = varName + '++;	// Increment\n';
			break;
			default:
				if (argument0 >= 0 ) {
					code = varName + ' += ' + argument0 + ';	// Increase\n';
				} else {
					code = varName + ' -= ' + (- argument0) + ';	// Decrease\n';
				}
			break;
		}
	} else {
		code = varName + ' += ' + argument0 + ';\t// Add to the variable "' + varName + '" the variable "' + argument0 + '"\n';
	}
    return code;
};

Blockly.Tinusaur['math_number_property'] = function (block) {
    // Check if a number is even, odd, prime, whole, positive, or negative
    // or if it is divisible by certain number. Returns true or false.
    var number_to_check = Blockly.Tinusaur.valueToCode(block, 'NUMBER_TO_CHECK', Blockly.Tinusaur.ORDER_NONE) || '0';
    var dropdown_property = block.getFieldValue('PROPERTY');
    var code;
    switch (dropdown_property) {
        case 'EVEN':
            code = number_to_check + ' % 2 == 0';
            break;
        case 'ODD':
            code = number_to_check + ' % 2 == 1';
            break;
        case 'WHOLE':
            // code = 'is_int(' + number_to_check + ')';
            code = "false /* Algorithm not provided WHOLE */"
            break;
        case 'POSITIVE':
            code = number_to_check + ' > 0';
            break;
        case 'NEGATIVE':
            code = number_to_check + ' < 0';
            break;
        case 'DIVISIBLE_BY':
            //code = number_to_check + ' % ' + divisor + ' == 0';
            code = "false /* Algorithm not provided DIVISIBLE_BY */"
            break;
        case 'PRIME':
            code = "false /* Algorithm not provided PRIME */"
            break;
    }
    return [code, Blockly.Tinusaur.ORDER_EQUALITY];
};
